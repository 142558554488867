import * as React from 'react'
import { DeepPartial } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { zodIs } from '@digital-magic/react-common/lib/utils/zod'
import { hasValue } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { IdObject } from '@api/endpoints/types'
import { isDeletableEntity } from '@api/endpoints/utils'
import {
  BuildingAddress,
  BuildingId,
  CreateBuildingRequest,
  useCreateBuilding,
  useDeleteBuilding,
  useGetAddresses,
  useGetBuilding,
  useUpdateBuilding
} from '@api/endpoints/buildings/houses'
import { FacadeColor, LayoutTypeId, LayoutTypeName, useGetLayoutTypes } from '@api/endpoints/buildings/layoutTypes'
import {
  EnergyClassId,
  FormOfOwnershipId,
  PropertyTypeId,
  useGetEnergyClasses,
  useGetFormsOfOwnership,
  useGetPropertyTypes
} from '@api/endpoints/settings'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { useSnackbar } from 'notistack'
import { Alert, Box } from '@mui/material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormCheckbox1, FormTextFieldStyled, FormTranslatedTextField, useFormZod } from '@controls/Form'
import { MenuItemEntry } from '@controls/types'
import { buildEnumOptions } from '@controls/utils'
import { BuildingFormStyled } from './styles'

const BuildingFormHistoryState = z.object({
  layoutTypeId: LayoutTypeId
})
export type BuildingFormHistoryState = z.infer<typeof BuildingFormHistoryState>

const FormValues = CreateBuildingRequest.extend({
  skipAddressValidation: z.boolean().optional()
})
type FormValues = z.infer<typeof FormValues>

type Params = Readonly<{
  buildingId: BuildingId
}>

export const BuildingForm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<Params>()
  const location = useLocation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const snackbar = useSnackbar()
  const translateValue = useTranslatedValue()

  const onCreateBuildingSuccess = ({ id }: IdObject): void => {
    navigate(generatePath(routes.Admin.BuildingsEdit, { buildingId: id }), { replace: true })
  }

  const onUpdateBuildingSuccess = (): void => {
    void getBuilding.refetch()
  }

  const onDeleteBuildingSuccess = (): void => {
    navigate(routes.Admin.Buildings)
  }

  const onCreateBuildingError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'AlreadyExistsError') {
      snackbar.enqueueSnackbar({ message: t('pages.admin.building.errors.already_exists') })
    } else {
      defaultErrorHandler(e)
    }
  }

  const onUpdateBuildingError: RequestErrorHandler = (e) => {
    if (isApiError(e)) {
      switch (e.code) {
        case 'AlreadyExistsError':
          snackbar.enqueueSnackbar(t('pages.admin.building.errors.already_exists'))
          break
        case 'ConstraintViolationError':
          snackbar.enqueueSnackbar(t('pages.admin.building.errors.update_constraint'))
          break
        default:
          defaultErrorHandler(e)
          break
      }
    }
  }

  const onDeleteBuildingError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.building.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const getEnergyClasses = useGetEnergyClasses({ onError: defaultErrorHandler })
  const getFormsOfOwnership = useGetFormsOfOwnership({ onError: defaultErrorHandler })
  const getPropertyTypes = useGetPropertyTypes({ onError: defaultErrorHandler })

  const getLayoutTypes = useGetLayoutTypes({ onError: defaultErrorHandler })
  const getAddressesAvailable = useGetAddresses('available', { onError: defaultErrorHandler })
  const getAddressesAll = useGetAddresses('all', { onError: defaultErrorHandler })

  const createBuilding = useCreateBuilding({ onError: onCreateBuildingError, onSuccess: onCreateBuildingSuccess })
  const updateBuilding = useUpdateBuilding({ onError: onUpdateBuildingError, onSuccess: onUpdateBuildingSuccess })
  const deleteBuilding = useDeleteBuilding({ onError: onDeleteBuildingError, onSuccess: onDeleteBuildingSuccess })

  const getBuilding = useGetBuilding(params.buildingId ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(params.buildingId)
  })

  const isLoading =
    getLayoutTypes.isFetching ||
    getAddressesAvailable.isFetching ||
    getAddressesAll.isFetching ||
    createBuilding.isLoading ||
    updateBuilding.isLoading ||
    deleteBuilding.isLoading ||
    (params.buildingId && getBuilding.isLoading)

  const onSubmit = (values: FormValues): void => {
    if (hasValue(params.buildingId)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { layoutTypeId, address, ...formValues } = values
      updateBuilding.mutate({
        id: params.buildingId,
        ...formValues
      })
    } else {
      createBuilding.mutate(values)
    }
  }

  const defaultValues: DeepPartial<FormValues> = React.useMemo(() => {
    if (zodIs(location.state, BuildingFormHistoryState)) {
      return {
        layoutTypeId: location.state.layoutTypeId
      }
    }

    return {}
  }, [location.state])

  const form = useFormZod<FormValues>({
    schema: FormValues,
    defaultValues: {
      ...defaultValues,
      hasSauna: false,
      isInteriorIncluded: false,
      isSanitaryIncluded: false
    }
  })

  const layoutTypeOptionValues: ReadonlyArray<MenuItemEntry<LayoutTypeName>> = React.useMemo(() => {
    if (getLayoutTypes.data) {
      return getLayoutTypes.data.map((lt) => ({
        label: lt.name,
        value: lt.id
      }))
    } else {
      return []
    }
  }, [getLayoutTypes.data])

  const addressOptionValues: ReadonlyArray<MenuItemEntry<BuildingAddress>> = React.useMemo(
    () =>
      (getAddressesAvailable.data ?? []).map((v) => ({
        label: v,
        value: v
      })),
    [getAddressesAvailable.data]
  )

  const facadeColorOptionValues: ReadonlyArray<MenuItemEntry<FacadeColor>> = React.useMemo(
    () => buildEnumOptions(FacadeColor, (v) => t(`enums.facade_color.${v}`)),
    [t]
  )

  const energyClassOptionValues: ReadonlyArray<MenuItemEntry<EnergyClassId>> = React.useMemo(
    () =>
      getEnergyClasses.data?.map((energyClass) => ({
        label: translateValue(energyClass.title),
        value: energyClass.id
      })) ?? [],
    [translateValue, getEnergyClasses.data]
  )

  const formOfOwnershipValues: ReadonlyArray<MenuItemEntry<FormOfOwnershipId>> = React.useMemo(
    () =>
      getFormsOfOwnership.data?.map((formOfOwnership) => ({
        label: translateValue(formOfOwnership.title),
        value: formOfOwnership.id
      })) ?? [],
    [translateValue, getFormsOfOwnership.data]
  )

  const propertyTypeValues: ReadonlyArray<MenuItemEntry<PropertyTypeId>> = React.useMemo(
    () =>
      getPropertyTypes.data?.map((propertyType) => ({
        label: translateValue(propertyType.title),
        value: propertyType.id
      })) ?? [],
    [translateValue, getPropertyTypes.data]
  )

  const isFormDisabled = false
  /*useMemo(
    () => hasValue(params.buildingId) && !isEditableEntity(getBuilding.data?.allowedActions),
    [params.buildingId, getBuilding.data]
  )*/

  const isDeleteDisabled = React.useMemo(
    () => hasValue(params.buildingId) && !isDeletableEntity(getBuilding.data?.allowedActions),
    [params.buildingId, getBuilding.data]
  )

  const onDeleteBuilding =
    (buildingId: BuildingId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteBuilding.mutate(buildingId)
      }
    }

  React.useEffect(() => {
    if (getBuilding.data) {
      const skipAddressValidation = !(getAddressesAll.data ?? []).includes(getBuilding.data.address)
      form.reset({
        ...getBuilding.data,
        // TODO: If we use correct types everywhere - we won't need such conversions
        basePrice: Number(getBuilding.data.basePrice),
        outdoorSquare: Number(getBuilding.data.outdoorSquare),
        indoorSquare: Number(getBuilding.data.indoorSquare),
        storeRoomSquare: Number(getBuilding.data.storeRoomSquare),
        terraceSquare: Number(getBuilding.data.terraceSquare),
        skipAddressValidation: skipAddressValidation
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBuilding.data, getAddressesAll.data])

  const skipAddressValidation = form.watch('skipAddressValidation')

  return (
    <BuildingFormStyled>
      <h1>{params.buildingId ? t('pages.admin.building.edit.title') : t('pages.admin.building.new.title')}</h1>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" rowGap={2} mb={4}>
          {(isFormDisabled || isDeleteDisabled) && (
            <Alert severity="warning">
              {isFormDisabled
                ? t('pages.admin.building.messages.edit_restricted')
                : t('pages.admin.building.messages.delete_restricted')}
            </Alert>
          )}
          <FormTextFieldStyled
            name="layoutTypeId"
            form={form}
            select
            selectOptions={layoutTypeOptionValues}
            label={t('pages.admin.building.form.field.name')}
            disabled={isLoading || isFormDisabled || hasValue(params.buildingId)}
          />
          <FormTextFieldStyled
            name="facadeColor"
            form={form}
            select
            selectOptions={facadeColorOptionValues}
            label={t('pages.admin.building.form.field.facade_color')}
            disabled={isLoading || isFormDisabled}
          />
          <FormCheckbox1
            name="skipAddressValidation"
            form={form}
            disabled={isLoading || isFormDisabled || hasValue(params.buildingId)}
            label={t('pages.admin.building.form.field.skip_address_validation')}
          />
          {skipAddressValidation || hasValue(params.buildingId) ? (
            <FormTextFieldStyled
              name="address"
              form={form}
              label={t('pages.admin.building.form.field.address')}
              disabled={isLoading || isFormDisabled || hasValue(params.buildingId)}
            />
          ) : (
            <FormTextFieldStyled
              name="address"
              form={form}
              select
              selectOptions={addressOptionValues}
              label={t('pages.admin.building.form.field.address')}
              disabled={isLoading || isFormDisabled}
            />
          )}
          <FormTextFieldStyled
            name="address2"
            form={form}
            label={t('pages.admin.building.form.field.address2')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="outdoorSquare"
            form={form}
            type="number"
            label={t('pages.admin.building.form.field.outdoor_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="indoorSquare"
            form={form}
            type="number"
            label={t('pages.admin.building.form.field.indoor_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="storeRoomSquare"
            form={form}
            type="number"
            label={t('pages.admin.building.form.field.store_room_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="terraceSquare"
            form={form}
            type="number"
            label={t('pages.admin.building.form.field.terrace_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="roomsCount"
            form={form}
            type="number"
            label={t('pages.admin.building.form.field.rooms_count')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="bedroomsCount"
            form={form}
            type="number"
            label={t('pages.admin.building.form.field.bedrooms_count')}
            disabled={isLoading || isFormDisabled}
          />
          <FormCheckbox1
            name="hasSauna"
            form={form}
            label={t('pages.admin.building.form.field.has_sauna')}
            disabled={isLoading || isFormDisabled}
          />
          <FormCheckbox1
            name="isInteriorIncluded"
            form={form}
            label={t('pages.admin.building.form.field.is_interior_included')}
            disabled={isLoading || isFormDisabled}
          />
          <FormCheckbox1
            name="isSanitaryIncluded"
            form={form}
            label={t('pages.admin.building.form.field.is_sanitary_included')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="energyClassId"
            form={form}
            select
            selectOptions={energyClassOptionValues}
            label={t('pages.admin.building.form.field.energy_class')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="formOfOwnershipId"
            form={form}
            select
            selectOptions={formOfOwnershipValues}
            label={t('pages.admin.building.form.field.form_of_ownership')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="propertyTypeId"
            form={form}
            select
            selectOptions={propertyTypeValues}
            label={t('pages.admin.building.form.field.property_type')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="basePrice"
            form={form}
            type="number"
            disabled={isLoading || isFormDisabled}
            label={t('pages.admin.building.form.field.base_price')}
          />
          <FormTranslatedTextField
            form={form}
            nameEng="buildingPermit.eng"
            nameEst="buildingPermit.est"
            nameRus="buildingPermit.rus"
            label={t('pages.admin.building.form.field.building_permit')}
            disabled={isLoading || isFormDisabled}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="usagePermit.eng"
            nameEst="usagePermit.est"
            nameRus="usagePermit.rus"
            label={t('pages.admin.building.form.field.usage_permit')}
            disabled={isLoading || isFormDisabled}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="accessToProperty.eng"
            nameEst="accessToProperty.est"
            nameRus="accessToProperty.rus"
            label={t('pages.admin.building.form.field.access_to_property')}
            optional
            onReset={(isNull) => form.setValue('accessToProperty', isNull ? undefined : { eng: '', est: '', rus: '' })}
            disabled={isLoading || isFormDisabled}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="accessInProperty.eng"
            nameEst="accessInProperty.est"
            nameRus="accessInProperty.rus"
            label={t('pages.admin.building.form.field.access_in_property')}
            optional
            onReset={(isNull) => form.setValue('accessInProperty', isNull ? undefined : { eng: '', est: '', rus: '' })}
            disabled={isLoading || isFormDisabled}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="encumbrances.eng"
            nameEst="encumbrances.est"
            nameRus="encumbrances.rus"
            label={t('pages.admin.building.form.field.encumbrances')}
            optional
            onReset={(isNull) => form.setValue('encumbrances', isNull ? undefined : { eng: '', est: '', rus: '' })}
            disabled={isLoading || isFormDisabled}
            multiline
          />
          {!isFormDisabled && (
            <Box display="flex" columnGap={2} mt={2}>
              <Button disabled={isLoading || isFormDisabled} type="submit">
                {t('global.buttons.submit')}
              </Button>
              {hasValue(params.buildingId) && (
                <ButtonWithConfirmation
                  color="error"
                  disabled={isLoading || isDeleteDisabled}
                  onConfirmResult={onDeleteBuilding(params.buildingId)}
                  confirmTitle={t('global.consent.delete.title')}
                  confirmMessage={t('global.consent.delete.message')}
                >
                  {t('global.buttons.delete')}
                </ButtonWithConfirmation>
              )}
            </Box>
          )}
        </Box>
      </form>
    </BuildingFormStyled>
  )
}
